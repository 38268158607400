.container {
  transition:
    opacity 1s ease,
    transform 1s ease;
  animation: fadeIn 1s;
}

.typography_title {
  padding: 2rem 2rem 1px 2rem;
}

.title {
  color: var(--white) !important;
  margin-top: 0px;
}

.content_carousel {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;
  height: 80vh;
}

.carousel {
  min-height: 300px;
  color: var(--white);
  line-height: 160px;
  text-align: "center";
  padding: 1rem;
  margin-bottom: 1rem;
}

.carousel_text {
  color: var(--white);
}

.carousel_description {
  color: var(--white);
  font-size: 1rem;
}

.carousel_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
}

.actions {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-top: 5%;
  align-items: center;
  gap: 20px;
}

.actions__button {
  color: var(--white) !important;
  border: 1px solid var(--white);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
