.client_page {
  background-color: var(--layout);
  padding: 10px 0 0 5vw;
  transition:
    opacity 1s ease,
    transform 1s ease;
  animation: fadeIn 1s;
}

.client_page__title {
  margin-top: 10px;
}

.client_page__descriptions {
  background-color: var(--white);
  padding: 10px 2vw;
  border-radius: 20px;
  box-shadow: var(--shadow);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
