.content_support {
  background-color: var(--primary);
  padding: 1rem;
  border-radius: 10px;
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
  text-align: center;
}

.card_support {
  background-color: var(--primary) !important;
  color: #fff !important;
  text-align: center;
  font-weight: bold;
}

.card_support:hover,
.card_support:active {
  background-color: #34659d !important;
}

.card_odd {
  background-color: white;
  border: 1px solid var(--primary);
  color: #000;
  text-align: center;
  font-weight: bold;
}

/* @keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */
