:root {
  --primary: #17549a;
  --secondary: #221d1e;
  --tertiary: #ec2320;
  --auxiliar: #b2b2b2;
  --layout: #f5f5f5;
  --border-radius: "15px";
  --white: #fff;
  --shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  --background-linear: linear-gradient(150deg, var(--primary), var(--secondary));
  --background-linear-mobile: linear-gradient(360deg, var(--primary), var(--secondary));
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.icon {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  border-radius: 8px;
  border: solid 1px var(--primary);
  justify-content: center;
}

.icon--white {
  color: var(--white);
}

.text--white {
  color: var(--white) !important;
}

.icon svg {
  color: var(--layout);
}

.img-background {
  height: 10vh;
  width: 10vh;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #045cac;
}

.spinner {
  width: 40px;
  height: 40px;
  background-color: #045cac;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.no-display {
  display: none;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.logo-img {
  width: 8rem;
}

.logo-img-mobile {
  width: 3rem;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
