.ticket_list__user {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}

.ticket_list__comments {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.ticket_list__comments__time {
  font-size: 1rem;
}

.ticket_list__extra {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}
