.public_layout {
  height: 100%;
}

.public_layout_sider {
  background: var(--background-linear) !important;
}

.public_layout__content__children {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
}
