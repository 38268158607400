.col__instruction {
  height: 74vh;
  padding: 8px;
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
}

.content__row {
  height: 74vh;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-15px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.content {
  background: var(--background-linear);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

@media (min-width: 200px) and (max-width: 991px) {
  .col__instruction {
    height: 100%;
  }

  .col__support {
    padding-top: 20px;
  }

  .content {
    background: var(--background-linear-mobile);
  }

  .content__row {
    height: 100%;
    flex-direction: column-reverse;
  }
}
