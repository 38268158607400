.login_form__row {
  text-align: center;
  min-height: 60vh;
}

.login_form__signin {
  width: 100%;
}

.recover_form__row {
  text-align: center;
}

.recover_form__recover {
  width: 100%;
}
