.list {
  overflow-y: scroll;
  height: 25vh;
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
  animation: fadeIn 1s;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 10px 0 0 10px;
}

.itemList {
  cursor: pointer;
}

.title {
  width: 100%;
  text-align: -webkit-center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
